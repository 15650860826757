/* :root {
  --bg: #0c0c0c;
  --border: #303030;
  --color: #7e7e7e;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: var(--bg);
}

h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  font-size: 30px;
  margin: 0;
}

.conatiner {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cosmos {
  position: absolute;
  width: 80px;
  height: 150px;
}
.cosmos—item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color);
  animation: fall-and-disapper 5s infinite ease-in-out;
  transform-origin: bottom;
  transform: scale(0);
}

.cosmos—item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes fall-and-disapper {
  0% {
    opacity: 0;
    top: 0;
    left: 0;
    transform: scale(1);
  }
  25% {
    opacity: 1;
    transform: scale(l);
  }

  50% {
    top: -200px;
    transform: scale(1);
  }
  100% {
    top: -150px;
    left: -300px;
    transform: scale(0);
  }
} */

canvas {
  position: absolute;
  top: 0;
  left: 0;
}
